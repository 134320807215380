import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BLOG_PLATFORM } from '../../constants/constants';
import { SearchStateService } from '../../services/search-state.service';
import { ShareDataService } from '../../services/share-data.service';
import { LangSwitch } from '../../services/lang-switch.service';
import { environment } from '../../../environments/environment';
import { CheckDeviceService } from '../../services/check-device.service';
import { BannerService } from '../../services/banner.service';
import { ArticleSnippetDto } from '../../models/blog-post-snippet.model';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  popularBlogPosts$ = new Observable<ArticleSnippetDto[]>;
  adId: string = environment.AdRevive.id;
  
  constructor(
    private bannerService: BannerService,
    private langSwitch: LangSwitch,
    private searchStateService: SearchStateService,
    public checkDeviceService: CheckDeviceService,
    public shareDataService: ShareDataService,
    public translate: TranslateService,
    private seo: SeoService
  ){}

  ngOnInit(): void {
    // reset search state
    this.searchStateService.setDefaults();
    this.langSwitch.setHomePage();

    this.seo.addAlternateLanguageTags(this.langSwitch);
    this.seo.addCanonicalLinkTag(window.location.href);

    //wait for translations to load
    this.translate.get("init").subscribe(x => {
      this.seo.setBasicTitle("hp_meta_title");
      this.seo.addMetaTagDescription(this.translate.instant("hp_meta_description"))
    });
    
    this.loadMostPopularArticles();
  }

  ngAfterViewInit(): void {
    this.bannerService.refreshRevive();
  }

  loadMostPopularArticles() {
    this.popularBlogPosts$ = this.shareDataService.fetchPopularBlog({ platform: BLOG_PLATFORM, limit: 3 });
  }
}
