<div class="yp-container card card--flow-y py-10">
    <h1 class="text-xl font-semibold mb-4">{{ "Companies" | translate }}:</h1>
    <ng-container *ngIf="result$ | async as result">
        <ul class="gap-x-4 grid grid-cols-1 mb20 md:grid-cols-2 gap-2" *ngIf="result?.indexes">
            <li *ngFor="let item of result.indexes">
                <a [routerLink]="item?.key" class="link-btn">
                    <span>{{ item?.key }}</span>
                </a>
            </li>
        </ul>
        <ul class="gap-x-4 gap-2 grid grid-cols-1 mb20 md:grid-cols-2" *ngIf="details">
            <li *ngFor="let detail of details">
                <a [routerLink]="detail?.url" class="link-btn">
                    <span>{{ detail?.title }}</span>
                </a>
            </li>
        </ul>
    </ng-container>
</div>