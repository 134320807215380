import { DefaultUrlSerializer, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';

export class CustomUrlSerializer extends DefaultUrlSerializer {
    override serialize(tree) {
        const segment = `/${this.serializeSegment(tree.root, true)}`;
        const query = this.serializeQueryParams(tree.queryParams);
        const fragment = typeof tree.fragment === `string` ? `#${this.encodeUriFragment(tree.fragment)}` : '';
        return `${segment}${query}${fragment}`;
    }

    serializeSegment(segment: UrlSegmentGroup, root: boolean): string {
        if (!segment.hasChildren()) {
          return this.serializePaths(segment);
        }     
      
        if (root) {
          const primary = segment.children[PRIMARY_OUTLET]
            ? this.serializeSegment(segment.children[PRIMARY_OUTLET], false)
            : '';
          const children: string[] = [];
      
          Object.entries(segment.children).forEach(([k, v]) => {
            if (k !== PRIMARY_OUTLET) {
              children.push(`${k}:${this.serializeSegment(v, false)}`);
            }
          });
      
          return children.length > 0 ? `${primary}(${children.join('//')})` : primary;
        } else {
          const children = this.mapChildrenIntoArray(segment, (v: UrlSegmentGroup, k: string) => {
            if (k === PRIMARY_OUTLET) {
              return [this.serializeSegment(segment.children[PRIMARY_OUTLET], false)];
            }
      
            return [`${k}:${this.serializeSegment(v, false)}`];
          });
      
          // use no parenthesis if the only child is a primary outlet route
          if (Object.keys(segment.children).length === 1 && segment.children[PRIMARY_OUTLET] != null) {
            return `${this.serializePaths(segment)}/${children[0]}`;
          }
      
          return `${this.serializePaths(segment)}/(${children.join('//')})`;
        }
    }

    serializeQueryParams(params: { [key: string]: any }): string {
        const strParams: string[] = Object.entries(params)
            .map(([name, value]) => {
                return Array.isArray(value)
                    ? value.map((v) => `${this.encodeUriQuery(name)}=${this.encodeUriQuery(v)}`).join('&')
                    : `${this.encodeUriQuery(name)}=${this.encodeUriQuery(value)}`;
            })
            .filter((s) => s);

        return strParams.length ? `?${strParams.join('&')}` : '';
    }

    encodeUriQuery(s: string): string {
        return this.encodeUriString(s).replace(/%3B/gi, ';');
    }

    encodeUriString(s: string): string {
        return encodeURIComponent(s);
            // .replace(/%40/g, '@')
            // .replace(/%3A/gi, ':')
            // .replace(/%24/g, '$')
            // .replace(/%2C/gi, ',');
    }

    encodeUriFragment(s: string): string {
        return encodeURI(s);
    }

    serializePaths(segment: UrlSegmentGroup): string {
        return segment.segments.map((p) => this.serializePath(p)).join('/');
    }

    serializePath(path: UrlSegment): string {
        return `${this.encodeUriSegment(path.path)}${this.serializeMatrixParams(path.parameters)}`;
    }

    encodeUriSegment(s: string): string {
        return this.encodeUriString(s)
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/\'/g, '%27')
            .replace(/\|/g, '%7C')
            .replace(/\*/g, '%2A')
            .replace(/\!/g, '%21')
            .replace("q_", "");
            //.replace(/%26/gi, '&');
    }

    serializeMatrixParams(params: { [key: string]: string }): string {
        return Object.entries(params)
            .map(([key, value]) => `;${this.encodeUriSegment(key)}=${this.encodeUriSegment(value)}`)
            .join('');
    }

    mapChildrenIntoArray<T>(
        segment: UrlSegmentGroup,
        fn: (v: UrlSegmentGroup, k: string) => T[],
    ): T[] {
        let res: T[] = [];
        Object.entries(segment.children).forEach(([childOutlet, child]) => {
            if (childOutlet === PRIMARY_OUTLET) {
                res = res.concat(fn(child, childOutlet));
            }
        });
        Object.entries(segment.children).forEach(([childOutlet, child]) => {
            if (childOutlet !== PRIMARY_OUTLET) {
                res = res.concat(fn(child, childOutlet));
            }
        });
        return res;
    }
}