<!-- headings section -->
<div class="yp-container pt-12 md:pb-12 pb-8">

    <!-- they wanted to hide this position-->
    <!-- <div class="align-center mt20 iframeWrap iframeWrap--mobile">
        <ins 
            [attr.data-revive-zoneid]="'banners.homePage.top' | translate" 
            [attr.data-revive-id]="adId"
            [attr.data-revive-target]="'_blank'"
            [attr.data-revive-block]="1">
        </ins>
    </div> -->

    <!-- headings -->
    <div class="gap-4 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2">

        <app-heading-box 
            [headingTitle]="'Group1'" 
            [headingIcon]="'construction-icon'">
        </app-heading-box>

        <app-heading-box 
            [headingTitle]="'Group2'" 
            [headingIcon]="'maison-icon'">
        </app-heading-box>

        <app-heading-box 
            [headingTitle]="'Group3'" 
            [headingIcon]="'services-icon'">
        </app-heading-box>
        
        <app-heading-box 
            [headingTitle]="'Group4'" 
            [headingIcon]="'sante-icon'">
        </app-heading-box>

    </div>
</div>

<!-- blog section TODO -->
<div class="yp-container" *ngIf="popularBlogPosts$ | async as blogPosts">
    <h2 class="text-3.5xl text-center mb-14 font-medium">
        {{ 'OurMostPopular' | translate }}
    </h2>
    <div class="pb-12 mb-12 border-b-1 border-blue-300">
        <div id="popular-post" class="gap-8 grid grid-cols-1 md:grid-cols-3">

            <app-blog-thumb
                *ngFor="let blogPost of blogPosts"
                [hTag]="'h3'"
                [article]="blogPost"
                [date]="blogPost?.modifiedDate" 
                [excerpt]="blogPost?.thumbnailText"
                [customTrackingAction]="'BlogMostPopularArticleClick'"
                [customTrackingCategory]="'HOME'">
            </app-blog-thumb>
            
        </div>

        <div class="flex justify-center">
            <app-button 
                [routeSegments]="['/blog', 'all']" 
                [label]="'MoreArticles' | translate"
                [style]="'outline'"
                class="inline-block mt-4">
            </app-button>
            
        </div>

    </div>
</div>

<!-- some texts -->
<div class="yp-container">
    <h2 class="text-3.5xl text-center mb-8 font-medium">
        {{ 'HpArticleTitle' | translate}}
    </h2>

    <app-text-image 
        [imageUrl]="'assets/images/yellow_hp_illu_1.svg'"
        [title]="'hp_article_heading_first' | translate"
        [description]="'hp_article_first' | translate"
        [label]="'hp_article_button_first' | translate"
        [href]="'hp_article_link_first' | translate">
    </app-text-image>

    <app-text-image 
        [imageUrl]="'assets/images/yellow_hp_illu_2.svg'"
        [title]="'hp_article_heading_second' | translate"
        [description]="'hp_article_second' | translate"
        [label]="'hp_article_button_second' | translate"
        [href]="'hp_article_link_second' | translate"
        [type]="'reverse'">
    </app-text-image>

    <!-- they wanted to hide this position-->
    <!-- <div class="align-center align-center--iframe">
        <div class="banner-box mt20">
            <ng-container *ngIf="checkDeviceService.isMobile$ | async; else elseBottomDesktop">
                <ins 
                    [attr.data-revive-zoneid]="'banners.homePage.bottomMobile' | translate" 
                    [attr.data-revive-id]="adId"
                    [attr.data-revive-target]="'_blank'"
                    [attr.data-revive-block]="1">
                </ins>
            </ng-container>
            <ng-template #elseBottomDesktop>
                <ins 
                    [attr.data-revive-zoneid]="'banners.homePage.bottom' | translate" 
                    [attr.data-revive-id]="adId"
                    [attr.data-revive-target]="'_blank'"
                    [attr.data-revive-block]="1">
                </ins>
            </ng-template>
        </div>
    </div> -->
</div>