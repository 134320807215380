<!-- popular articles -->
<div class="flex flex-col gap-4" *ngIf="articles?.length > 0">
    <h2 class="text-2xl text-blue-600 text-center font-medium">
        {{ 'OurMostPopular' | translate }}
    </h2>
    <div id="popular-post" class="flex flex-col gap-4">

        <app-blog-thumb
            *ngFor="let article of articles"
            [hTag]="'h3'"
            [article]="article"
            [date]=""
            [excerpt]="">
        </app-blog-thumb>

    </div>
</div>