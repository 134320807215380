import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../components/modal/modal.service';
import { SearchArguments } from '../../models/search-arguments.model';
import { Badge, Contacts, DetailPageType, Poi, ShareDataService } from '../../services/share-data.service';
import { ListingModel } from '../../models/listing.model';
import { DebugItem } from '../../models/debug-item.model';
import { EMPTY, map, Observable, of, shareReplay, Subject, Subscription, switchMap, takeUntil, tap } from 'rxjs';
import { EventService } from '../../services/event.service';
import { ListingDetailViewModel } from '../../models/listing-detail-results.model';
import { LangSwitch } from '../../services/lang-switch.service';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from '../../services/tracking.service';
import { UrlHelper } from '../../helpers/url-builder.helper';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrl: './detail.component.scss'
})
export class DetailComponent implements OnInit, OnDestroy {
  detail$: Observable<ListingDetailViewModel>;
  listing: ListingModel;
  pois: Poi[] = [];
  detailPageType: DetailPageType;
  currentId: string;
  rivals: ListingModel[] = [];
  badges: Badge[] = [];
  requestForms: any;
  debug: DebugItem[];
  adKey: string = "";
  showTrafficDescription: boolean = false;
  trafficPageLength: number = 0;

  // folder part
  childListingId: string = null;
  childListing: ListingModel;
  childPois: Poi[] =[];
  childContacts: Contacts;
  childFetched: boolean = false;

  isChildListing: boolean = false;
  private eventSubscription: Subscription;

  loading: boolean = false;

  detailUrl: string = null;

  // modal
  @ViewChild('mapModalDetail')
  mapModalDetail: TemplateRef<Element>;

  private destroy$ = new Subject<void>();

  constructor(
    private shareDataService: ShareDataService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private langSwitch: LangSwitch,
    private seo: SeoService,
    private translate: TranslateService,
    private tracking: TrackingService,
    private urlHelper: UrlHelper){
      this.loading = true;
    }
    

  ngOnInit(): void {
    this.eventSubscription = this.eventService.handleMapBtnClick.subscribe(_ => {
      this.handleOpenMap();
    });

    document.addEventListener('closeMapControlClick', this.close.bind(this));
    document.addEventListener('closeForm', this.close.bind(this));
    document.addEventListener('closeModal', this.close.bind(this));

    this.route.paramMap
      .subscribe(params => {
        // parent or child id if folder
        this.currentId = params.get("id");
        // child id
        this.childListingId = params.get("childId");

        this.loadData();
      });

    this.eventService.handleSelectChild.subscribe((childListingId) => {
      this.childListingId = childListingId;
      this.loadChildListing(this.childListingId);
    });
  }

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }

    this.seo.removeStructuredData();
    this.seo.removeAlternateLanguageTags();
    this.seo.removeCanonicalLinkTag();

    this.destroy$.next();
    this.destroy$.complete();
  }

  setSearchArgs(id: string) {
    var req = new SearchArguments();
    req.id = id;

    return req;
  }

  loadData() {
    // nevime jestli se jedna o listing nebo o parent nebo o child
    const req = this.setSearchArgs(this.currentId);
    this.detail$ = this.shareDataService.fetchDetail(req).pipe(
      switchMap((data: ListingDetailViewModel) => {
        this.listing = data.listing;

        if (this.listing === null) {
          this.router.navigate(["/"]);
          return EMPTY;
        }

        // set listing as temp child
        this.childListing = this.listing;

        this.detailPageType = this.shareDataService.getDetailPageType(this.listing);
        this.pois = this.shareDataService.getDetailPois(this.listing);
        this.rivals = data.rivals;
        this.requestForms = this.shareDataService.getRequestForm(this.childListing);
        this.showTrafficDescription = this.handleTrafficDescription(this.listing);

        // adKey for banners
        this.adKey = data?.advertsCodeInfo?.adKey || null;

        this.langSwitch.SetDetailPage(data.listing);

        this.seo.addAlternateLanguageTags(this.langSwitch);
        this.seo.addCanonicalLinkTag(this.langSwitch['link' + this.translate.currentLang.toUpperCase()]);
        this.seo.setDetailPageTitle(this.listing.Title, this.listing.City, this.listing.Category.length > 0 ? this.listing.Category[0] : "");

        this.addMetaDescription();

        // tracking detail page load
        this.tracking.trackEvent("DETAIL", "DetailPageLoad", this.listing?.Id);

        // if we get only one listingID
        if (this.isSingleListing()) {  
          this.addStructuredData(this.childListing);

          this.isChildListing = true;
          this.loading = false;    
          return of(data);
        } else {
          this.isChildListing = false;  
          // if we get CHILDA fetch it, if we get PARENT fetch first CHILD
          const childListing = this.childListingId !== null ? this.childListingId : this.listing.FolderAddresses?.[0]?.id;

          return this.shareDataService.fetchDetail(this.setSearchArgs(childListing)).pipe(
            tap((childData: ListingDetailViewModel) => {
              if (childData?.listing) {
                // set child
                this.loading = false;
                this.childListing = childData.listing;

                this.addStructuredData(this.childListing, true);
              }
            })
          );
        }
      }),
      shareReplay(1)
    );
  }

  isSingleListing() {
    const isFolder = this.detailPageType === "folder";
    const hasChildListingId = this.childListingId !== null;
    const hasParent = this.listing?.folders.length > 0;

    const isChild = (isFolder && !hasChildListingId && hasParent);

    return !isFolder || isChild;
  }

  addStructuredData(listing: ListingModel, isFolder: boolean = false) {
    this.seo.removeStructuredData();

    const contacts = this.shareDataService.getContacts(listing);
    const phone = this.shareDataService.getContactByType(contacts, "phone");
    const email = this.shareDataService.getContactByType(contacts, "email");

    const route = isFolder ? 'Routes.Detail.ListingFolderDetail' : 'Routes.Detail.ListingDetail';

    this.translate.get(route)
      .pipe(takeUntil(this.destroy$))
      .subscribe((translatedRoute) => {
        if (isFolder) {
          this.detailUrl = this.urlHelper.getFolderDetailPageRoute(
            translatedRoute, listing?.Id, this.listing?.Id, listing?.Title, true
          );
        } else {
          this.detailUrl = this.urlHelper.getDetailPageRoute(
            translatedRoute, listing?.City, listing?.Id, listing?.Title, true
          );
        }
      });

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": listing?.Title || "",
      "url": this.detailUrl,
      "logo": listing?.Logo?.path || "",
      "image": listing?.Logo?.path || "",
      "description": listing?.Description || "",
      "telephone": phone.length > 0 && phone?.[0]?.hasContact ? phone?.[0]?.contact?.[0].value : "",
      "email": email.length > 0 && email?.[0]?.hasContact ? email?.[0]?.contact?.[0].value : "",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": listing?.Address || "",
        "addressLocality": listing?.Locality2 || "",
        "addressRegion": listing?.CityDistrict || "",
        "postalCode": listing?.Zip || "",
        "addressCountry": listing?.Country || ""
      }
    };

    this.seo.addStructuredData(structuredData);
  }

  loadChildListing(childListingId: string) {
    this.detail$ = this.shareDataService.fetchDetail(this.setSearchArgs(childListingId)).pipe(
      map((child) => {
        this.isChildListing = true;
        this.childListing = child?.listing;
        this.pois = this.shareDataService.getDetailPois(child?.listing);
        this.showTrafficDescription = this.handleTrafficDescription(child?.listing);
        this.close();

        this.tracking.trackEvent("DETAIL", "FolderChildLoad", this.childListing?.Id );

        this.addStructuredData(this.childListing);

        return child;
      }),
      shareReplay(1)
    );
  }

  handleOpenMap() {
    this.modalService.open(this.mapModalDetail, {
      classes: [
        'modal-inner',
        'yp-container--2xl',
        'h-full',
        'md\:h-80',
        'p-0'
      ],
      size: {
        width: '100%',
      },
    });
  }

  close() {
    this.modalService.close();
  }

  handleTrafficDescription(listing) {
    const hasDescriptionOrImage = Math.max(listing?.TrafficDescriptions?.length, listing?.TrafficImages?.length) > 0;
    return hasDescriptionOrImage;
  }

  displayServesLocation() {
    if(this.rivals.length > 0 && this.listing.CategoryId.length > 0 && this.listing.TaxonomyInfo.displaysServes)
      return this.listing.City;
    return "";
  }

  addMetaDescription() {
    if (this.childListing?.MetaDescription && this.childListing?.MetaDescription != "") {
      this.seo.addMetaTagDescription(this.childListing?.MetaDescription);
      return;
    }

    let description = ""
    const keywords = this.childListing?.Keywords.join(", ");

    if (this.childListing.SpecialOffer && this.childListing?.SpecialOffer != "")
      description += this.childListing?.SpecialOffer;

    description += ", " + this.childListing?.Title
    description += ", " + this.childListing?.City

    if (this.childListing?.Reviews.length > 0)
      description += ". " + this.translate.instant("Reviews") + " " + this.childListing?.Reviews[0].review;

    if (keywords != "")
      description += ". " + this.translate.instant("Keywords") + " " + keywords;

    this.seo.addMetaTagDescription(description.substring(0, 160) + "...");
  }
}