import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef, HostListener, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UrlHelper } from '../../../helpers/url-builder.helper';
import { ShareDataService, SuggestType } from '../../../services/share-data.service';
import { EventService } from '../../../services/event.service';
import { SearchArguments, SearchType } from '../../../models/search-arguments.model';
import { SuggersterResponse } from '../../../models/suggester-response.model';
import { SuggesterComponent } from '../../suggester/suggester.component';
import { SearchStateService } from '../../../services/search-state.service';
import { Utils } from '../../../utils/utils';

interface QueryParams {
  geolocation?: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() 
  pageType: 'all' | 'home' | 'dynamic' | 'normalized' | 'noSearch' = "all";

  @Input("locale")
  currentLocale: string = "be_nl";

  @Output()
  handleFormSubmit: EventEmitter<void> = new EventEmitter<void>();

  whatValue: string = "";
  whereValue: string = "";
  whatDefaultValue: string = "";

  reducedFormOpen: boolean = false;

  // sticky
  elementPosition: any;

  sticky: boolean = false;
  placeholderHeight: number;

  @ViewChild('element') 
  element: ElementRef;

  // formGroup
  searchForm: FormGroup;

  searchArgs: SearchArguments = new SearchArguments();

  wherePlaceholder: string = "";

  // suggester
  whatSuggestions: SuggersterResponse[] = [];
  whereSuggestions: SuggersterResponse[] = [];
  showSuggestions: boolean = false;
  showMyLocation: boolean = false;

  @ViewChild('suggesterWhat') 
  suggesterWhat: SuggesterComponent;

  @ViewChild('suggesterWhere') 
  suggesterWhere: SuggesterComponent;

  @ViewChild('whatField') 
  whatField: ElementRef;
  
  @ViewChild('whereField') 
  whereField: ElementRef;

  private isSettingWhereValueProgrammatically: boolean = false;

  constructor(
    public translate: TranslateService, 
    private router: Router, 
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public shareDataService: ShareDataService,
    public eventService: EventService,
    public searchStateService: SearchStateService,
    public utils: Utils,
    private urlHelper: UrlHelper
  ) {}

  ngOnInit(): void {
    // form
    this.searchForm = this.formBuilder.group({
      what: ['', [Validators.required, Validators.minLength(3)]],
      where: ['']
    });

    // what
    this.searchStateService.getWhat().subscribe((what: string) => {
      this.searchForm.patchValue({ what });
      this.whatValue = what;
    });

    // where
    this.searchStateService.getWhere().subscribe((where: string) => {
      this.isSettingWhereValueProgrammatically = true;
      this.searchForm.patchValue({ where });
      this.whereValue = where;
    });

    // page type
    this.getPageType();
  }

  ngAfterViewInit(): void {
    if(this.element && this.element.nativeElement) {
      this.elementPosition = this.element.nativeElement.offsetTop;
    }
    this.calculatePlaceholderHeight();

    // what suggest
    this.shareDataService
      .fetchSuggestion("what", this.searchForm)
      .subscribe(suggestion => {
        this.suggesterWhere.hideSuggestion();
        this.whatSuggestions = suggestion;
      });

    // where suggest
    this.shareDataService
      .fetchSuggestion("where", this.searchForm)
      .subscribe(suggestion => {
        this.suggesterWhat.hideSuggestion();
        this.whereSuggestions = suggestion;
      });

      this.searchForm.get('what').valueChanges.subscribe(value => {
        this.whatValue = value;
      });

      this.searchForm.get('where').valueChanges.subscribe(value => {
        this.whereValue = value;
      });
  }

  // sticky
  calculatePlaceholderHeight() {
    if (this.sticky) {
      this.placeholderHeight = this.element.nativeElement.offsetHeight;
    } else {
      this.placeholderHeight = 0;
    }
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
      this.calculatePlaceholderHeight()
    } else {
      this.sticky = false;
    }
  }
  // sticky

  // search
  onSubmit() {
    // reset filters
    this.searchStateService.setFilterDefaults();
    // get search type
    this.searchStateService.setSearchType(SearchType.Dynamic);

    let whatValue = this.searchForm.get("what").value;
    let whereValue = this.searchForm.get("where").value;

    // redirect to homepage
    if(this.urlHelper.checkUrl(whatValue) || this.urlHelper.checkUrl(whereValue)){
      this.searchStateService.setWhat(null);
      this.searchStateService.setWhere(null);
      this.router.navigate(['/']);
      return
    }

    // what
    if (whatValue && whatValue.length > 0) {
      this.searchStateService.setWhat(whatValue);
    }

    // where or geo
    let queryParams = {} as QueryParams;
    // check if ask for geoLoaction
    if (whereValue === null) {
      this.searchStateService.getGeoLocation().subscribe(geoLocation => {
        if (geoLocation && geoLocation.length > 0) {
          queryParams.geolocation = geoLocation;
        }
      })
    } else {
      // where
      this.searchStateService.setWhere(whereValue);
    }
    this.wherePlaceholder = this.shareDataService.getWherePlaceholder(whereValue, this.isHome());

    // emit for filters
    this.eventService.handleSearchClick.emit(this.searchStateService.getCurrentValues());

    this.hideMyLocationSuggestion();

    console.log(whatValue);

    // navigate
    let routeToSearch = this.urlHelper.getSearchPageRouteWithPage(this.translate.instant("Routes.Search.DynamicWhatWhereSearch"), whatValue, whereValue, 0);

    if (queryParams.hasOwnProperty('geolocation')) {
      routeToSearch += this.shareDataService.serializeQueryParams(queryParams);
    }

    this.router.navigate([routeToSearch], { queryParamsHandling: 'merge' });

    // close reduced form
    this.handleReduceFormState(false);
  }

  getPageType() {
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data)
    ).subscribe(data => {
      this.pageType = data['pageType'] || 'all';
    });
  }

  isAll(): boolean {
    return this.pageType === 'all';
  }

  isHome(): boolean {
    return this.pageType === 'home';
  }

  isSearch(): boolean {
    return this.pageType === 'dynamic' || this.pageType === 'normalized';
  }

  isNoSearch(): boolean {
    return this.pageType === 'noSearch';
  }

  // suggester
  selectSuggestion(suggestion: string, type: SuggestType): void {
    const cleanedSuggestion = suggestion.replace(/<b>|<\/b>/g, '');
    this.searchForm.get(type).setValue(cleanedSuggestion);
    this.shareDataService.selectValue$.next(true);
  }

  showWhereSuggestionsOnFocus(): void {
    if (this.suggesterWhere) {
      this.showMyLocationSuggestion();
      this.suggesterWhere.showSuggestion();
    }
  
    if (this.suggesterWhat) {
      this.suggesterWhat.hideSuggestion();
    }
  }
  
  showWhatSuggestionsOnFocus(): void {
    if (this.suggesterWhat) {
      this.hideMyLocationSuggestion();
      this.suggesterWhat.showSuggestion();
    }
  
    if (this.suggesterWhere) {
      this.suggesterWhere.hideSuggestion();
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: any): void {
    if (!this.whatField?.nativeElement?.contains(event.target) && this.suggesterWhat?.showSuggestions === true || 
        !this.whereField?.nativeElement?.contains(event.target) && this.suggesterWhere?.showSuggestions === true) {
      this.hideMyLocationSuggestion();
      this.suggesterWhat.hideSuggestion();
      this.suggesterWhere.hideSuggestion();

      this.handleReduceFormState(false);
    }
  }

  showMyLocationSuggestion() {
    this.showMyLocation = false;
    if (!this.isSettingWhereValueProgrammatically && this.whereSuggestions.length === 0) {
      this.showMyLocation = true;
    }

    this.isSettingWhereValueProgrammatically = false;
  }

  hideMyLocationSuggestion() {
    this.showMyLocation = false;
  }

  handleMyLocation() {
    this.shareDataService.getLocation().then((geoLocation: string) => {
      this.searchArgs.geoLocation = geoLocation;
      this.searchStateService.setGeoLocation(geoLocation);
      this.searchStateService.setWhere(null);

      if (this.whereField) {
        this.whereField.nativeElement.placeholder = this.translate.instant("AroundMe");
      }
      this.hideMyLocationSuggestion();
    }).catch((error) => {
      console.log(error);
    });
  }

  handleMapModal() {
    this.eventService.handleMobileMapModal.emit();
  }

  handleReduceFormState(state?: boolean): void {
    if (state) {
      this.reducedFormOpen = state;
    } else {
      this.reducedFormOpen = !this.reducedFormOpen;
    }
  }

  handleRaducedSearchForm(): void {
    this.handleReduceFormState();
    // setTimeout(() => {
    //   this.whatField.nativeElement.focus();
    // }, 0);
  }
}