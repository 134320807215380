import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlHelper } from '../../../../helpers/url-builder.helper';
import { ArticleSnippetDto } from '../../../../models/blog-post-snippet.model';
import { ShareDataService } from '../../../../services/share-data.service';
import { TrackingCategory } from '../../../../services/tracking.service';

@Component({
  selector: 'app-blog-thumb',
  templateUrl: './blog-thumb.component.html',
  styleUrl: './blog-thumb.component.scss'
})
export class BlogThumbComponent implements OnInit {
  @Input('article') article: ArticleSnippetDto;
  @Input('date') date: string;
  @Input('excerpt') excerpt: string;
  @Input('customTrackingAction') customTrackingAction: string = null;
  @Input('customTrackingCategory') customTrackingCategory: TrackingCategory;
  @Input('hTag') hTag: string = "h2";

  formatedDate: string = "";
  href: string;

  constructor(
    public translate: TranslateService,
    private shareDataService: ShareDataService,
    private urlHelper: UrlHelper) { }

  ngOnInit() {
    if(this.date) {
      this.formatedDate = this.shareDataService.formatDate(this.date);
    }

    this.translate.get("Routes.Blog.BlogDetail").subscribe((url: string) => {
      this.href = this.urlHelper.getBlogPageRoute(url, this.article?.headings[0], this.article?.id, this.article?.title);
    });
  }
}
