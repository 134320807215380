<div class="yp-container card card--flow-y py-10">
    <h1 class="text-xl font-semibold mb-4">{{ "Categories" | translate }}:</h1>
    <ul class="flex flex-wrap mb-4">
        <li *ngFor="let index of indexes"
            class="mr-3">
            <a [routerLink]="index?.url" class="link-btn">
                <span>{{ index?.label }}</span>
            </a>
        </li>
    </ul>
    <ng-container *ngIf="categories$ | async">
        <ul class="gap-x-4 gap-2 grid grid-cols-1 mb20 md:grid-cols-2" *ngIf="categories">
            <li *ngFor="let category of categories">
                <a [routerLink]="category?.url" class="link-btn">
                    <span>{{ category?.label }}</span>
                </a>
            </li>
        </ul>
        <!-- <app-pagination
            (emitPageNum)="switchPage($event)"
            [pageSize]="take"
            [numFound]="numFound">
        </app-pagination> -->
    </ng-container>
</div>