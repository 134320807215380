import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentFormComponent } from '../../../../components/appointment-form/appointment-form.component';
import { modalOptions } from '../../../../components/modal/modal-options-values';
import { ModalService } from '../../../../components/modal/modal.service';
import { QuoteFormComponent } from '../../../../components/quote-form/quote-form.component';
import { ContactInfo } from '../../../../helpers/contact.helper';
import { UrlHelper } from '../../../../helpers/url-builder.helper';
import { ListingModel } from '../../../../models/listing.model';
import { CheckDeviceService } from '../../../../services/check-device.service';
import { SearchStateService } from '../../../../services/search-state.service';
import { Contacts, DetailPageType, Poi, ShareDataService } from '../../../../services/share-data.service';

@Component({
  selector: 'app-detail-actions',
  templateUrl: './detail-actions.component.html',
  styleUrl: './detail-actions.component.scss'
})
export class DetailActionsComponent implements OnInit {
  @Input('listing')
  listing: ListingModel;

  @Input()
  childListing: ListingModel;

  @Input('pois')
  pois: Poi[];

  @Input('isChildListing')
  isChildListing: boolean = false;

  contacts: Contacts;

  phones: ContactInfo[];
  websites: ContactInfo[];
  emails: ContactInfo[];
  webshops: ContactInfo[];
  menus: ContactInfo[];
  folders: ContactInfo[];

  @Input()
  requestForms;

  @Input('detailPageType')
  detailPageType: DetailPageType;

  showWebshop: boolean = false;
  showWebsite: boolean = false;

  parent: ListingModel;
  child: ListingModel;

  modalOptions = {};
  modalBookingOptions = {
    classes: [
      'modal-inner', 
      'yp-container--2xl', 
      'p-0'
    ]
  };

  // modal
  @ViewChild('iframeContainer', { static: false }) 
  iframeContainer: ElementRef;

  // sticky phone button
  @ViewChild('actionsRef') 
  actionsRef: ElementRef;

  @ViewChild('stickyButtonRef') 
  stickyButtonRef: ElementRef;

  constructor(
    private modalService: ModalService,
    private router: Router,
    private searchStateService: SearchStateService,
    private shareDataService: ShareDataService,
    private translate: TranslateService,
    private urlHelper: UrlHelper,
    public checkDeviceService: CheckDeviceService
  ) { }

  ngOnInit(): void {    
    this.parent = this.isChildListing ? this.childListing : this.listing;
    // if folder we need child otherwise normal listing
    this.child = this.childListing || this.listing;

    this.contacts = this.shareDataService.getContacts(this.child);

    this.phones = this.shareDataService.getContactByType(this.contacts, "phone");
    this.websites = this.shareDataService.getContactByType(this.contacts, "website");
    this.emails = this.shareDataService.getContactByType(this.contacts, "email");
    this.webshops = this.shareDataService.getContactByType(this.contacts, "webshop");
    this.menus = this.shareDataService.getContactByType(this.contacts, "menu");
    this.folders = this.shareDataService.getContactByType(this.contacts, "folder");

    this.showWebshop = this.webshops.length > 0 && this.webshops[0].hasContact;
    this.showWebsite = (this.websites.length > 0 && this.websites[0].hasContact) || this.listing?.TrafficLink.length > 0;

    const listingId = { listingId: `${this.listing.Id}` };
    this.modalOptions = {...modalOptions, ...listingId };
  }

  ngAfterViewInit() {
    this.setupScrollListener();
  }

  openQuoteForm() {
    this.modalService.open(QuoteFormComponent, this.modalOptions);
  }

  openAppointmentForm() {
    this.modalService.open(AppointmentFormComponent, this.modalOptions);
  }

  openBookingModa(view: TemplateRef<Element>) {
    this.modalService.open(view, { ...modalOptions, ...this.modalBookingOptions });
  }

  setupScrollListener() {
    if (!this.actionsRef || !this.stickyButtonRef) {
      return;
    }

    const el = this.actionsRef.nativeElement;
    const bottomMapOpener = this.stickyButtonRef.nativeElement;
    const elHeight = el.offsetHeight;
    const elPosition = el.offsetTop;

    if (window.innerWidth <= 767) {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset >= elPosition + elHeight) {
          bottomMapOpener.style.bottom = "2rem";
        } else {
          bottomMapOpener.style.bottom = "";
        }
      });
    }
  }

  navigateBack() {
    this.translate.get("Routes.Search.DynamicWhatWhereSearch").subscribe((url: string) => {
      this.searchStateService.getPage().subscribe(page => {
        const { what } = this.searchStateService.getCurrentValues();

        // if user went directly to DETAIL
        let routeToSearch = this.urlHelper.getSearchPageRoute(url, this.listing?.Category?.[0], this.listing?.City);

        // if user went from SERP
        if (what !== null) {
          routeToSearch = this.urlHelper.getSearchPageRoute(url, what);
        }

        if (page > 1) {
          routeToSearch = this.urlHelper.getSearchPageRouteWithPage(url, this.listing?.Category?.[0], this.listing?.City, page)
        }

        this.router.navigate([routeToSearch]);
      });        
    });
  }
}
