import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { Endpoints } from '../../../constants/endpoints';
import { UrlHelper } from '../../../helpers/url-builder.helper';
import { KeyValuePair } from '../../../models/query-results.model';
import { DataService } from '../../../services/data.service';
import { LangSwitch } from '../../../services/lang-switch.service';
import { CategoryLink } from '../../detail/components/categories/categories.component';
import { Title } from '@angular/platform-browser';

export class KeywordRequest {
  letter: string = '';
  take: number = 0;
  skip: number = 0;
}

export interface KeywordResponse {
  keywords: KeyValuePair<string, number>[];
  numFound: number;
}

@Component({
  selector: 'app-keyword-index',
  templateUrl: './keyword-index.component.html',
  styleUrl: './keyword-index.component.scss'
})
export class KeywordIndexComponent implements OnInit, OnDestroy {
  take: number = 100;
  letter: string = null;
  page: number;

  keywords$: Observable<KeyValuePair<string, number>[]>;

  numFound: number = 0;
  request: KeywordRequest = new KeywordRequest();

  alphabet: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  indexes: CategoryLink[] = [];
  keywords: CategoryLink[] = [];

  destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private langSwitch: LangSwitch,
    private data: DataService,
    private translate: TranslateService,
    private urlHelper: UrlHelper,
    private title: Title
  ){}

  ngOnInit(): void {
    this.route.paramMap.subscribe((map) => {
      this.letter = map.get("letter") || "A";
      this.page = parseInt(map.get("page")) || 1;
      
      this.initIndexes();
      this.loadIndex();
      this.langSwitch.setKeywordIndex(this.letter, this.page)

      this.translate.get('Keywords').subscribe(keyword => {
        this.title.setTitle(`${keyword} | ${this.letter} ${this.page} | ${document.location.host}`);
      })
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadIndex() {
    this.request.letter = this.letter;
    this.request.skip = this.page > 1 ? (this.page - 1) * this.take : 0;
    this.request.take = this.take;
    this.keywords$ = this.data.post<KeyValuePair<string, number>[]>(Endpoints.KEYWORDS(this.translate.currentLang), this.request)
      .pipe(switchMap(data => {
        this.initKeywords(data);
        this.numFound = 0;
        return of(data);
      }));
  }

  initIndexes(): void {
    this.translate.get("Routes.Index.KeywordIndex")
      .pipe(takeUntil(this.destroy$))
      .subscribe(route => {
        this.indexes = this.alphabet.map(letter => {
          return new CategoryLink(
            letter,  
            this.urlHelper.getIndexPageRoute(route, letter)
          );
        })
      });
  }

  initKeywords(keywords: KeyValuePair<string,number>[]): void {
    this.keywords = keywords.map(keyword => {
      return new CategoryLink(
        keyword.key, 
        this.urlHelper.getSearchPageRoute(this.translate.instant('Routes.Search.NormalizedWhatWhereSearch'), keyword.key)
      );
    })
  }

  switchPage(event) {
    if (event && event.page) {
      this.page = event.page;
    }
    this.translate.get("Routes.Index.KeywordIndex")
      .pipe(takeUntil(this.destroy$))
      .subscribe((url: string) => {
        const nextPageUrl = url.replace(":letter", this.letter).replace(":page", `${this.page}`);
        this.router.navigate([nextPageUrl]);
      });
  }
}