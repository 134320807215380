import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlHelper } from '../../../../helpers/url-builder.helper';
import { ListingModel } from '../../../../models/listing.model';
import { CategoryLink } from '../categories/categories.component';

@Component({
  selector: 'app-extended-categories',
  templateUrl: './extended-categories.component.html',
  styleUrl: './extended-categories.component.scss'
})
export class ExtendedCategoriesComponent {
  @Input('listing')
  listing: ListingModel;

  keywords: string[] = [];

  categoryLinks: CategoryLink[] = [];
  cityCategoryLink: CategoryLink = null;
  keywordLinks: CategoryLink[] = [];

  constructor(
    private urlHelper: UrlHelper,
    private translate: TranslateService) {}

  ngOnInit() {
    this.initKeywords();
    this.initCategories();
  }

  initKeywords() {
    if (this.listing) {
      this.keywords = this.listing?.AllKeywords.filter(keyword =>
        !this.listing?.Category.includes(keyword) && keyword !== this.listing?.City
      );
    } else {
      this.keywords = [];
    }
  }

  initCategories() {
    console.log("categories");
    if (this.listing?.Category?.length > 0) {
      this.categoryLinks = this.listing.Category.map(category => {
        const url = this.urlHelper.getSearchPageRoute(this.translate.instant("Routes.Search.NormalizedWhatWhereSearch"), category);
          return new CategoryLink(category, url);
        }
      )
    }

    if (this.listing?.City) {
      if (this.listing?.Category[0] !== null) {
        const url = this.urlHelper.getSearchPageRoute(this.translate.instant("Routes.Search.NormalizedWhatWhereSearch"), this.listing.Category[0], this.listing.City);
        this.cityCategoryLink = new CategoryLink(this.listing.City, url);
      }
    }

    if (this.listing?.IsPaid && this.keywords?.length > 0) {
      this.keywordLinks = this.keywords.map(keyword => {
        const url = this.urlHelper.getSearchPageRoute(this.translate.instant("Routes.Search.NormalizedWhatWhereSearch"), keyword, this.listing.City);
        return new CategoryLink(keyword, url);
      });
    }
    
  }
}
