import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { UrlHelper } from '../../../helpers/url-builder.helper';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent implements OnInit, OnDestroy {
  products: Product[] = [];
  keywordsRoute: string;
  categoriesRoute: string;

  destroy$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private urlHelper: UrlHelper){}

  ngOnInit(): void {
    this.fillProducts();
    this.initKeywords();
    this.initCategories();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFooterMessage() {
    const message : string = this.translate.instant('FooterMessage');
    var date = new Date(); 
    return message.replace('{year}',date.getFullYear().toString())
  }

  fillProducts() {
    this.products.push(new Product('Products_Website','Products_Website_Url'));
    this.products.push(new Product('Products_Sea','Products_Sea_Url'));
    this.products.push(new Product('Products_Seo','Products_Seo_Url'));
    this.products.push(new Product('Products_NetSync','Products_NetSync_Url'));
    this.products.push(new Product('Products_GoudenGids','Products_GoudenGids_Url'));
    this.products.push(new Product('Products_SocialAds','Products_SocialAds_Url'));
    this.products.push(new Product('Products_Sitee_Header','Products_Sitee_Url'));
  }

  initKeywords(): void {
    this.translate.get('Routes.Index.KeywordIndex')
      .pipe(takeUntil(this.destroy$))
      .subscribe(route => {
        this.keywordsRoute = this.urlHelper.getIndexPageRoute(route);
      })
  }

  initCategories(): void {
    this.translate.get('Routes.Index.CategoryIndex')
      .pipe(takeUntil(this.destroy$))
      .subscribe(route => {
        this.categoriesRoute = this.urlHelper.getIndexPageRoute(route);
      })
  }
}

class Product {
    title: string = '';
    url: string = '';
    
    constructor(_title: any, _url: any){
      this.title = _title;
      this.url = _url;
    }
}