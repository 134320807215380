import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AppointmentFormComponent } from '../../../components/appointment-form/appointment-form.component';
import { BannerService } from '../../../services/banner.service';
import { CheckDeviceService } from '../../../services/check-device.service';
import { ContactInfo } from '../../../helpers/contact.helper';
import { Contacts, DetailPageType, Poi, ShareDataService } from '../../../services/share-data.service';
import { environment } from '../../../../environments/environment';
import { ListingModel } from '../../../models/listing.model';
import { modalOptions } from '../../../components/modal/modal-options-values';
import { ModalService } from '../../../components/modal/modal.service';
import { QuoteFormComponent } from '../../../components/quote-form/quote-form.component';
import { TranslateService } from '@ngx-translate/core';
import { UrlHelper } from '../../../helpers/url-builder.helper';
import { Utils } from '../../../utils/utils';
import { SearchStateService } from '../../../services/search-state.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-free-page',
  templateUrl: './free-page.component.html',
  styleUrl: './free-page.component.scss'
})
export class FreePageComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('listing')
  listing: ListingModel;

  @Input('detailPageType')
  detailPageType: DetailPageType;

  @Input('requestForms')
  requestForms;

  @Input('rivals')
  rivals: ListingModel[] = [];

  @Input('pois')
  pois: Poi[] = [];

  @Input('adKey')
  adKey: string = null;
  
  contacts: Contacts;

  phones: ContactInfo[];
  websites: ContactInfo[];
  emails: ContactInfo[];
  webshops: ContactInfo[];
  hasParameter: boolean = false;

  showWebshop: boolean = false;
  showWebsite: boolean = false;

  modalOptions = {};

  adId: string = environment.AdRevive.id;

  disableFinancialInfo: boolean = false;
  
  // banners
  observers: MutationObserver[] = [];
  @ViewChildren('resizeBanner') 
  observingElements: QueryList<ElementRef>;

  // sticky phone button
  @ViewChild('actionsRef') 
  actionsRef: ElementRef;

  @ViewChild('stickyButtonRef') 
  stickyButtonRef: ElementRef;

  private destroy$ = new Subject<void>();

  constructor(
    private bannerService: BannerService,
    private modalService: ModalService,
    public checkDeviceService: CheckDeviceService,
    public shareDataService: ShareDataService,
    public translate: TranslateService,
    public urlHelper: UrlHelper,
    public utils: Utils,
    private searchStateService: SearchStateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.contacts = this.shareDataService.getContacts(this.listing);

    this.phones = this.shareDataService.getContactByType(this.contacts, "phone");
    this.websites = this.shareDataService.getContactByType(this.contacts, "website");
    this.emails = this.shareDataService.getContactByType(this.contacts, "email");
    this.webshops = this.shareDataService.getContactByType(this.contacts, "webshop");

    this.showWebshop = this.webshops.length > 0 && this.webshops[0].hasContact;
    this.showWebsite = (this.websites.length > 0 && this.websites[0].hasContact) || this.listing?.TrafficLink.length > 0;

    const listingId = { listingId: `${this.listing.Id}` };
    this.modalOptions = {...modalOptions, ...listingId };
  }

  ngAfterViewInit(): void {
    this.setupScrollListener();

    this.bannerService.initMutationObservers(this.observers, this.observingElements);
    this.bannerService.refreshRevive();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openQuoteForm() {
    this.modalService.open(QuoteFormComponent, this.modalOptions);
  }

  openAppointmentForm() {
    this.modalService.open(AppointmentFormComponent, this.modalOptions);
  }

  setupScrollListener() {
    if (!this.actionsRef || !this.stickyButtonRef) {
      return;
    }

    const el = this.actionsRef.nativeElement;
    const bottomMapOpener = this.stickyButtonRef.nativeElement;
    const elHeight = el.offsetHeight;
    const elPosition = el.offsetTop;

    if (window.innerWidth <= 767) {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset >= elPosition + elHeight) {
          bottomMapOpener.style.bottom = "2rem";
        } else {
          bottomMapOpener.style.bottom = "";
        }
      });
    }
  }

  navigateBack() {
    this.translate.get("Routes.Search.DynamicWhatWhereSearch").subscribe((url: string) => {
      this.searchStateService.getPage().subscribe(page => {
        const { what } = this.searchStateService.getCurrentValues();

        // if user went directly to DETAIL
        let routeToSearch = this.urlHelper.getSearchPageRoute(url, this.listing?.Category?.[0], this.listing?.City);

        // if user went from SERP
        if (what !== null) {
          routeToSearch = this.urlHelper.getSearchPageRoute(url, what);
        }

        if (page > 1) {
          routeToSearch = this.urlHelper.getSearchPageRouteWithPage(url, this.listing?.Category?.[0], this.listing?.City, page)
        }

        this.router.navigate([routeToSearch]);
      });        
    });
  }
}
