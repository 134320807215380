import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as L from 'leaflet';
import { LocationModel } from '../../models/location.model';
import { EventService } from '../../services/event.service';
import { Poi } from '../../services/share-data.service';

type ResultPageType = 'dynamic' | 'normalized';
type DetailPageType =  'free' | 'basic' | 'traffic' | 'folder';
type MapType = 'modal' | 'results' | 'detail';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input('resultPageType')
  resultPageType: ResultPageType;

  @Input('detailPageType')
  detailPageType: DetailPageType;

  @Input('isOh')
  isOh: boolean = false;

  @Input('id')
  mapId: string = 'map';

  @Input('mode')
  mode: MapType;

  @Input('pois')
  pois: Poi[];

  @Input('mapType')
  mapType: 'folder';

  @Input('analyzedLocation')
  analyzedLocation: LocationModel

  @Output() 
  handleModalClick = new EventEmitter<void>();
  //showMap: boolean = true;

  @Input('disableMap')
  disableMap: boolean = false;
  
  private selectedPoiIndex: number;
  private baseLayer: any;
  private markersLayer: any;
  private tiles: string;
  private map: L.Map | undefined;
  public detailPoi: any;
  public lang: any;

  constructor(
    public eventService: EventService,
    private translate: TranslateService) {
    this.detailPoi = null;
    this.selectedPoiIndex = 0;
    this.baseLayer = null;
    this.markersLayer = null;
    this.tiles = '//osm.fcrmedia.com/{z}/{x}/{y}.png';
  }

  ngOnInit(): void {
    this.handleDisplayMapOnDetail();
    this.lang = this.translate.currentLang;
  }
  
  ngAfterViewInit(): void {
    if (this.disableMap === false) {
      this.initMap();
    }
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
      this.map = undefined;  // Clean up the Leaflet map instance
    }
  }

  handleDisplayMapOnDetail() {
    if (this.pois.length === 1) {
      if (this.pois?.[0]?.location?.latitude && this.pois?.[0]?.location?.longitude) {
        this.disableMap = false;
      } else {
        this.disableMap = true;
      }
    }
  }

  getMapMode() {
    if (!this.mode) {
      if (this.resultPageType) {
        this.mode = 'results';
      } else if (this.detailPageType) {
        this.mode = 'detail';
      }
    } else {
      this.mode = 'modal';
    }
  }

  isResult(): boolean {
    return (this.resultPageType === 'dynamic' || 
            this.resultPageType === 'normalized');
  }

  isDetail(): boolean {
    return (this.detailPageType === 'traffic' ||
            (this.detailPageType === 'folder' && this.mapType !== 'folder') ||
            this.detailPageType === 'basic' ||
            this.detailPageType === 'free');
  }

  initMap(): void {
    this.map = L.map(this.mapId, {
      zoomControl: false,
      attributionControl: false,
      center: [39.8282, -98.5795],
      zoom: 3
    });

    this.getMapMode();

    if (this.mode === 'modal') {
      L.control.zoom({ position: 'topleft' }).addTo(this.map);
      this.map.addControl(new (this.closeMapControl()));
    
      if (this.isDetail()) {
        this.map.addControl(new (this.planRoute()));
      }

      if (this.isResult()) {
        // Handle the condition if isResult is true
      }
    
      L.control.attribution({ position: 'bottomright' }).addTo(this.map);
    } else if (this.isDetail() || this.isResult()) {
      L.control.attribution({ position: 'topright' }).addTo(this.map);
      this.map.addControl(new (this.openMapModal()));
    }

    const tileLayer = L.tileLayer(this.tiles, {
      maxZoom: 17,
      attribution: `&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap-contributors</a>`
    });

    this.baseLayer = L.layerGroup();
    this.map.addLayer(tileLayer);
    this.map.addLayer(this.baseLayer);

    this.refreshPois();

    // fix map open in modal
    setTimeout(() => {
      this.refresh();
      this.fitPois()
    }, 0);
  }

  closeMapControl() {
    return L.Control.extend({
      options: { position: 'topright' },

      // Function called when the control is added to the map
      onAdd: _ => {
        // Get the language from the language switch element
        const lang = this.translate.currentLang;

        // Create an anchor element for the control
        const container = L.DomUtil.create('a');
        container.className = "bg-white close cursor-pointer flex items-center leaflet-control p-2 py-1 rounded-sm shadow hover:underline";

        // Set the inner HTML of the control based on the selected language
        container.innerHTML = `<svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 18 18" class="h-2.5 w-2.5">
                                   <use xmlns:xlink="http://www.w3.org/1999/xlink" href="#close-icon"></use>
                               </svg><span class="ml-2 cursor-pointer">${lang == 'fr' ? "Fermer la carte" : lang == 'nl' ? "Sluit de kaart" : "Close the map"}</span>`;

        // Event listener for the control click event
        container.addEventListener('click', function () {
          // Dispatch a custom event to handle the click action
          const event = new Event('closeMapControlClick');
          document.dispatchEvent(event);
        });

        return container; // Return the created control container
      }
    })
  }

  getDirectionsControl() {
    return L.Control.extend({
      // ...
    })
  }

  enlargeMapControl() {
    return L.Control.extend({
      // ...
    })
  }

  openMapModal() {
    return L.Control.extend({
      options: {
        position: 'bottomright'
      },

      // Function called when the control is added to the map
      onAdd: _ => {
        const lang = this.translate.currentLang;

        // Create a container element for the control
        var container = L.DomUtil.create('span');
        container.classList.add('btn', 'btn--outline', 'border-1', 'flex', 'gap-2');
        container.style.cursor = 'pointer';
        container.id = 'openMapDetail';

        // Create an SVG element for the control icon
        var svg = '<svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 24 24" class="h-5 self-start w-5">' +
          '<use xmlns:xlink="http://www.w3.org/1999/xlink" href="#plan-route-icon"></use>' +
          '</svg>';

        // Create a span element for the control text based on the language
        var span = L.DomUtil.create('span');
        span.innerHTML = lang === "fr" ? "Voir la carte" : lang === "nl" ? "Bekijk de kaart" : "Show the map";

        // Set the HTML content of the control
        container.innerHTML = svg;
        container.appendChild(span);

        // Event listener for the control click event to initialize a modal
        container.addEventListener("click", _ => {
          this.eventService.handleMapBtnClick.emit();
        });

        return container; // Return the created control container
      }
    })
  }

  planRoute() {
    return L.Control.extend({
      options: { position: 'bottomleft' },

      // Function called when the control is added to the map
      onAdd: _ => {
        // Create a container element for the control
        var container = L.DomUtil.create('span');
        container.classList.add('btn', 'btn--outline', 'border-1', 'flex', 'gap-2');
        container.style.cursor = 'pointer';
        container.id = 'planRouteButton';

        // Create an SVG element for the control icon
        var svg = '<svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 24 24" class="h-5 self-start w-5">' +
          '<use xmlns:xlink="http://www.w3.org/1999/xlink" href="assets/icons/icons.svg#plan-route-icon"></use>' +
          '</svg>';

        // Create a span element for the control text based on the language
        var span = L.DomUtil.create('span');
        var lang = document.documentElement.lang;
        span.innerHTML = lang === "fr" ? "Itinéraire" : lang === "nl" ? "Route" : "Directions";

        // Set the HTML content of the control
        container.innerHTML = svg;
        container.appendChild(span);

        // Event listener for the control click event
        container.addEventListener('click', _ => {
          // Get the coordinates of a point of interest
          const singlePoi = this.getPoi(0);
          const coordinates = `${singlePoi.location.latitude},${singlePoi.location.longitude}`;

          let route = `//www.google.com/maps/dir/?api=1&destination=${coordinates}&travelmode=driving`;
          if (/Macintosh|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            route = `//maps.apple.com/?daddr=${coordinates}&dirflg=d&t=h`;
          }
          window.open(route, "_blank");
        });

        return container; // Return the created control container
      }
    })
  }

  refreshPois() {
    // Clear the base layer if it exists
    if (this.baseLayer) {
      this.baseLayer.clearLayers();
    }

    // If the mode is set to DETAIL, render a single POI based on the selectedPoiIndex
    if (this.isDetail()) {
      const poi = this.getPoi(this.selectedPoiIndex);
      if (poi?.location.latitude && poi?.location.longitude) {
        this.renderSinglePoi(poi);
      } else {
        
        //this.showMap = false;
      }
    }

    // If the mode is set to RESULTS or MODAL, render multiple pois on the map
    if (this.isResult() || this.mode === 'modal') {
      this.renderMultiplePois();
    }
  }

  handlePoiClick(marker: any, index: number) {
    // Add a click event listener to the marker
    marker.addEventListener('click', (e) => {
        const resultItem: HTMLElement = document.querySelector(`.modal [data-index='${index}']`);
        if (!resultItem) return;

        this.handleSelectedPoi(e);
        this.handleHighlightResult(resultItem);

        const resultListHolder: HTMLElement = document.querySelector("#map-results");
        if (!resultListHolder) return;

        // Calculate and scroll to the result in the list based on screen size
        let destinationToScroll;
        if (window.innerWidth > 767) {
            destinationToScroll = resultItem.offsetTop;
        } else {
            destinationToScroll = index * (resultItem.getBoundingClientRect().width + 12);
        }

        this.scrollToResult(resultListHolder, destinationToScroll, window.innerWidth > 767);
    });
  }

  handleSelectedPoi = (e) => {
    const SELECTED_POI_CLASS = "selected-poi";
    // Reset previous marker styling if present
    const selectedPoi = document.getElementById(this.mapId).querySelector(`.${SELECTED_POI_CLASS}`);
    if (selectedPoi) {
        selectedPoi.classList.remove(SELECTED_POI_CLASS);
        selectedPoi.querySelector("svg").className.baseVal = "text-blue-200 hover:text-blue-600";
    }

    // Apply hover styling to the clicked marker
    e.target._icon.classList.add(SELECTED_POI_CLASS);
    e.target._icon.querySelector("svg").className.baseVal = "text-blue-600 hover:text-blue-200";
  };

  handleHighlightResult = (resultItem) => {
    // handle hightlight class
    const highlighted = document.querySelector('#map-results .bg-blue-300');
    if (highlighted) {
        highlighted.classList.remove("bg-blue-300");
    }
    resultItem.classList.add("bg-blue-300");
  };

  scrollToResult(elem, destination, directionScrollY = false) {
    var el = elem || window;
    var h = elem ? el : document.querySelector('.results-clone .horizontal-list__items');
    var start = directionScrollY ? (elem ? el.scrollTop : window.pageYOffset) : h.scrollLeft;
    var startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    if (directionScrollY) {
        var max = el.scrollHeight - el.offsetHeight;
        if (destination > max) {
            destination = max;
        }
        if (destination < 0) {
            destination = 0;
        }
    } else {
        var max = el.scrollWidth - el.offsetWidth;
        if (destination > max) {
            destination = max;
        }
        if (destination < 0) {
            destination = 0;
        }
    }

    // browser doesn't support animation
    if ('requestAnimationFrame' in window === false) {
        if (directionScrollY) {
            el.scroll(0, destination);
        } else {
            h.scrollLeft = destination;
        }
        return;
    }

    // animation scroll
    const scroll = () => {
        var now = 'now' in window.performance ? performance.now() : new Date().getTime();
        var time = Math.min(1, (now - startTime) / 300);

        if (directionScrollY) {
            const yScroll = Math.ceil(time * (destination - start) + start);
            el.scroll(0, yScroll);

            // reach to result
            if (Math.ceil(destination) === Math.ceil(yScroll)) {
                return;
            }
        } else {            
            h.scrollLeft = Math.ceil(time * (destination - start) + start);
            
            // reach to result
            if (Math.ceil(destination) === Math.ceil(h.scrollLeft)) {
                return;
            }
        }

        requestAnimationFrame(scroll);
    }

    scroll();
  }

  createMarker(poi: Poi) {
    // HTML content for the marker icon
    const poiContent = `<svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 16 20" class="text-blue-200 hover:text-blue-600">
                        <use xmlns:xlink="http://www.w3.org/1999/xlink" href="#poi-icon"></use>
                    </svg>`;

    // Create a Leaflet marker at the specified location with the custom icon
    const marker = L.marker([poi.location.latitude, poi.location.longitude], {
      icon: L.divIcon({
        className: '',
        iconSize: [26, 32],
        html: poiContent
      })
    });

    // If index is provided and non-negative, handle click event for the marker
    if (poi.index >= 0) {
      this.handlePoiClick(marker, poi.index);
    }

    return marker; // Return the created marker
  }

  renderMultiplePois() {
    // Create markers for each point of interest in the pois array
    const markers = this.pois?.map(poi => this.createMarker(poi));

    // Create a layer group from the markers
    this.markersLayer = L.layerGroup(markers);

    // If baseLayer doesn't exist, exit the function
    if (!this.baseLayer) return;

    // Add the markers layer to the baseLayer
    this.baseLayer.addLayer(this.markersLayer);

    // Determine map behavior based on the number of markers
    if (markers?.length === 1) {
      // If there's only one marker, set the map view to focus on that marker's location with a specific zoom level
      const center = markers[0].getLatLng();
      this.map.setView(center, 15);
    } else {
      // zoom to the center
      if (this.analyzedLocation) {
          let lat = this.analyzedLocation.latitude;
          let lng = this.analyzedLocation.longitude;

          const center = L.marker([lat, lng]).getLatLng();
          setTimeout(_ => this.map.setView(center, 12), 666);
      } else {
        // If there are multiple markers, fit the map view to encompass all markers
        this.fitPois();
      }
    }
  }

  renderSinglePoi(poi: Poi) {
    // Check if the poi is valid
    if (poi === null) return;

    // Create a marker for the point of interest at its location
    const marker = this.createMarker(poi);

    // Add the marker to the map
    this.map.addLayer(marker);

    // Set the map view to focus on the marker's location with a specific zoom level
    const center = marker.getLatLng();
    this.map.setView(center, 17);
  }

  fitPois() {
    // Calculate the bounding box based on the points of interest
    const bounds = this.getBoundingBox(this.pois);

    // Fit the points of interest (pois) to the calculated bounding box
    if (this.map) {
      this.map.fitBounds(bounds);
    }
  }

  getBoundingBox(pois) {
    pois = pois || this.pois;

    // Initialize variables to store latitude and longitude values
    let maxLat = -90,
      minLat = 90,
      maxLon = -180,
      minLon = 180;

    // Iterate through each point of interest (poi)
    pois?.forEach(poi => {
      const { latitude, longitude } = poi.location;

      // Update max and min latitude/longitude values
      maxLat = Math.max(maxLat, latitude);
      minLat = Math.min(minLat, latitude);
      maxLon = Math.max(maxLon, longitude);
      minLon = Math.min(minLon, longitude);
    });

    // Define a percentage buffer for the bounding box
    const pctBuffer = 0.07;
    const latBuffer = (maxLat - minLat) * pctBuffer;
    const lonBuffer = (maxLon - minLon) * pctBuffer;

    // Extend the bounding box by adding two additional locations
    const sw = L.latLng(minLat - latBuffer, minLon - lonBuffer);
    const ne = L.latLng(maxLat + latBuffer, maxLon + lonBuffer);

    // Return a Leaflet LatLngBounds object based on the calculated locations
    return L.latLngBounds(sw, ne);
  }

  reRenderPois(poiClass: string, wrapperId: string) {
    // ...
  }

  zoomDetail(poiIndex: number) {
    // ...
  }

  refresh() {
    // Invalidates the map size to adapt to changes in its container
    if (this.map) {
      this.map.invalidateSize();
    }
  }

  getPoi(poiIndex: number) {
    return this.pois?.find(poi => poi.index === poiIndex) || null;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // refresh map
    this.refresh();
    this.fitPois();
  }

}