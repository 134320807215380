import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BlogPost } from '../models/blog-post.model';
import { LangSwitch } from './lang-switch.service';

export enum MetaTagType {
  NOINDEX = 'noindex, follow',
  ROBOTS_DEFAULT = 'noarchive,noodp,noydir'
}

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public rendererFactory: RendererFactory2,
    private meta: Meta,
    private title: Title,
    private translate: TranslateService,) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  
  private structuredDataScript: HTMLScriptElement;
  private languages = ["nl", "fr", "en"];
  private renderer: Renderer2;

  //// Set language to HTML tag ////

  setLanguage(lang: string) {
    this.document.documentElement.lang = lang;
  }

  //// Structured data ////

  addStructuredData(data: object): void {
    this.structuredDataScript = document.createElement('script');
    this.structuredDataScript.type = 'application/ld+json';
    this.structuredDataScript.text = JSON.stringify(data);
    document.body.appendChild(this.structuredDataScript);
  }

  removeStructuredData() {
    if (this.structuredDataScript) {
      document.body.removeChild(this.structuredDataScript);
      this.structuredDataScript = null;
    }
  }

  //// Alternate Language tags ////

  addAlternateLanguageTags(ls: LangSwitch): void {
    // remove existing alternate languages
    this.removeAlternateLanguageTags();

    this.languages.forEach(language => {
      const linkUrl = ls['link' + language.toUpperCase()];
      const link = this.createLink(this.encodeUrl(linkUrl), language);
      this.renderer.appendChild(document.head, link);
    });
  }

  removeAlternateLanguageTags(): void { 
    const links = document.querySelectorAll('[hreflang]');
    links.forEach(link => {
      this.renderer.removeChild(document.head, link);
    });
  }

  createLink(url: string, lang?: string, rel?: string, ) {
    const link = this.renderer.createElement('link');
    link.rel = rel || 'alternate';
    link.href = url;
    if (lang) {
      link.hreflang = lang;
    }

    return link;
  }

  addAlternateLanguageTagsToBlogPage(blogDetail: BlogPost, ls: LangSwitch) {
    // remove existing alternate languages
    this.removeAlternateLanguageTags();

    this.languages.forEach(language => {
      const title = 'title' + language.charAt(0).toUpperCase() + language.slice(1);
      if (blogDetail?.[title] != "") {
        const linkUrl = ls['link' + language.toUpperCase()];
        const link = this.createLink(this.encodeUrl(linkUrl), language);
        this.renderer.appendChild(document.head, link);
      }
    });
  }

  //// Meta tags ////
  
  addMetaTagRobots(content: MetaTagType = MetaTagType.ROBOTS_DEFAULT) {
    this.meta.updateTag({ name: 'robots', content: content });
  }

  // - description
  addMetaTagDescription(description: string): void {
      const desc = this.removeHtmlTags(this.encodeQuotes(description));
      this.meta.updateTag({ name: 'description', content: desc });
  }

  removeMetaTagDescription(): void {
    const tag = this.meta.getTag('name="description"');
    if (tag) {
        this.meta.removeTagElement(tag);
    }
  }
  
  // - canonical
  addCanonicalLinkTag(url: string) {
    // remove existing canonical
    this.removeCanonicalLinkTag();

    const link = this.createLink(this.encodeUrl(url), null, "canonical");
    this.renderer.appendChild(document.head, link);
  }

  removeCanonicalLinkTag(): void {
    const tag = document.querySelector("link[rel='canonical']");
    if (tag) {
      this.renderer.removeChild(document.head, tag)
    }
  }

  // - helpers
  removeHtmlTags(text: string): string {
      const div = document.createElement("div");
      div.innerHTML = text;
      return div.textContent || div.innerText || "";
  }
  
  encodeQuotes(text: string): string {
      return text.replaceAll("\"", "&quot;")
  }

  encodeUrl(url: string) {
    if(!url) return null;
    return encodeURI(url)
      .replace(
        /[;?@&=+$,#!'()*]/g,
        (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
      );
  }

  //// Title tag ////

  /**
  * Sets title via translate pipe.
  *
  * @param value - prop name from i18n json
  */
  setBasicTitle(value: string) {
    this.translate.get(value).subscribe((title: string) => this.title.setTitle(title));
    this.translate.onLangChange.subscribe(_ => {
      this.translate.get(value).subscribe((title: string) => this.title.setTitle(title));
    })
  }

  setDetailPageTitle(title: string, location: string, category: string): void {
    let sb: string[] = [];

    if (title && title.trim()) {
      sb.push(title.trim());
    }

    if (location && location.trim()) {
      sb.push(location.trim());
    }

    if (category && category.trim()) {
      sb.push(category.trim());
    }

    let result = sb.join(' - ');

    this.addTitleTag(result);
  }

  setResultPageTitle(what: string, where: string): void {
    let sb: string[] = [];

    if (what && what.trim()) {
      sb.push(what.trim());
    }

    if (where && where.trim()) {
      if (sb.length > 0) {
        sb.push(' - ');
      }
      sb.push(where.trim());
    }

    let result = sb.join('');

    this.addTitleTag(result);
  }

  addTitleTag(result: string): void {
    if (result.length > 75) {
      this.title.setTitle(result.substring(0, 70) + '...')
    }

    const hostname = ' | ' + document.location.host;

    if ((hostname.length + result.length) > 75) {
      this.title.setTitle(result);
    }
    this.title.setTitle(result + hostname);
  }
}