import { Component, EventEmitter, HostListener, OnInit, Output, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { distinctUntilChanged, filter, map, mergeMap, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../components/modal/modal.service';
import { ListingSearchResultsModel } from '../../models/listing-search-results.model';
import { SearchArguments, SearchType } from '../../models/search-arguments.model';
import { ListingModel } from '../../models/listing.model';
import { Poi, SearchResults, ShareDataService } from '../../services/share-data.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { BLOG_PLATFORM } from '../../constants/constants';
import { EventService } from '../../services/event.service';
import { CheckDeviceService } from '../../services/check-device.service';
import { SearchStateService } from '../../services/search-state.service';
import { LangSwitch } from '../../services/lang-switch.service';
import { UrlHelper } from '../../helpers/url-builder.helper';
import { gaObjectValues, TrackingService } from '../../services/tracking.service';
import { BannerService } from '../../services/banner.service';
import { ArticleSnippetDto } from '../../models/blog-post-snippet.model';
import { environment } from '../../../environments/environment';
import { MetaTagType, SeoService } from '../../services/seo.service';

export type ResultPageType = 'dynamic' | 'normalized';

export interface Editorials {
  top: string;
  bottom: string;
}

export interface ListingGroup {
  listings: ListingModel[];
  numFound: number | null;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent implements OnInit {
  searchResults$: Observable<SearchResults>;
  eshop$: Observable<boolean>;
  mostPopularArticles$: Observable<ArticleSnippetDto[]>;
  editorials$: Observable<Editorials>
  fallbackA1$ = new Observable<boolean>;
  pois: Poi[] = [];
  listings: ListingModel[];
  resultPageType: ResultPageType;
  currentUrl: string = '';
  showPosts: boolean = true;

  // modal
  @ViewChild('resultModal') resultModal: TemplateRef<Element>;

  @Output() handleOpenMapClick: EventEmitter<void> = new EventEmitter<void>();

  loading: boolean = false;
  data: ListingSearchResultsModel;
  results: ListingSearchResultsModel;
  searchArgs: SearchArguments = new SearchArguments();
  showFallbackA1: boolean = false;
  relatedCities$ = new Observable<string[]>;
  adKey: string = "";
  adId: string = environment.AdRevive.id;
  modalOpt = {
    classes: [
      'modal-inner',
      'yp-container--2xl',
      'h-full',
      'md\:h-80',
      'p-0'
    ],
    size: {
      width: '100%',
    },
  };
  rawData: any;
  numFound: number = 0;
  localResultsLink: string;
  trackingSearchData: gaObjectValues = {};
  
  showEshopButton: boolean = false;
  showDebug: boolean = false;
  currentRoute: string;

  private destroy$ = new Subject<void>();

  constructor(
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public shareDataService: ShareDataService,
    public eventService: EventService,
    public checkDeviceService: CheckDeviceService,
    private renderer: Renderer2,
    private searchStateService: SearchStateService,
    private langSwitch: LangSwitch,
    private urlHelper: UrlHelper,
    private trackingService: TrackingService,
    private bannerService: BannerService,
    private seo: SeoService) {
    this.getPageType();
  }

  ngOnInit() {
    console.log("init");
    this.handleMobileMap();
    this.handleMapModalEvents();
    this.handleQueryParams();
    this.handleRouteParamsAndLoadResults();
  }

  ngAfterViewInit(): void {
    this.bannerService.refreshRevive();
  }

  ngOnDestroy() {
    this.seo.removeAlternateLanguageTags();
    this.seo.removeCanonicalLinkTag();
    this.seo.addMetaTagRobots();
    // finish subscribes with takeUtil
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleMapModalEvents() {
    this.eventService.handleMapBtnClick.pipe(takeUntil(this.destroy$)).subscribe(_ => {
      this.openModalTemplate(this.resultModal);
    });

    this.eventService.handleMobileMapModal.pipe(takeUntil(this.destroy$)).subscribe(_ => {
      this.openModalTemplate(this.resultModal);
    });

    document.addEventListener('closeMapControlClick', this.close.bind(this));
  }

  private handleQueryParams() {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.searchStateService.setGeoLocation(params['geolocation'] || null);
      this.showDebug = params['debug'] === 'true';
    });
  }

  private handleRouteParamsAndLoadResults() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
      //const currentSearchArgs = this.searchStateService.getCurrentValues();

      // if user arrived from google take params from url otherwise take it from search args
      //if (currentSearchArgs.what === null) {
        this.searchStateService.setWhat(params.get("what"));
        this.searchStateService.setWhere(params.get("where"));
        this.searchStateService.setPage(parseInt(params.get("page")) ? parseInt(params.get("page")) : 1);
      //}

      this.setResultPageType();
      this.loadResults();
    });
  }

  private setResultPageType(): void {
    const searchType = this.resultPageType === 'normalized' ? SearchType.Normalized : SearchType.Dynamic;
    this.searchStateService.setSearchType(searchType);

    const isLocalSearch = (this.route.toString().includes('LocalResults') ||
                           this.route.toString().includes('LokaleResultaten') ||
                           this.route.toString().includes('ResultatsLocaux'));

    if (isLocalSearch) {
      this.searchStateService.setSearchType(SearchType.Local);
    }
  }

  private getPageType(): void {
    this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data)
    ).pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.resultPageType = data['pageType'] || 'dynamic';
    });
  }

  public openModalTemplate(view: TemplateRef<Element>): void {
    this.modalService.open(view, this.modalOpt, null, "MapModalOpen", "SEARCH");
  }

  public close(): void {
    this.modalService.close();
  }

  getSearchRoute(callback) {
    this.translate.get('init')
    .pipe(
      takeUntil(this.destroy$),
      switchMap(() => this.searchStateService.getSearchType()),
      distinctUntilChanged()
    )
    .subscribe(type => {
      let route: string;
      switch (type) {
        case SearchType.Dynamic:
          route = this.translate.instant("Routes.Search.DynamicWhatWhereSearch");
          break;
        case SearchType.Normalized:
          route = this.translate.instant("Routes.Search.NormalizedWhatWhereSearch");
          break;
        case SearchType.Local:
          route = this.translate.instant("Routes.Search.LocalResults");
          break;
        default:
          route = '';
      }

      if (route) {
        callback(route, this.searchStateService.getCurrentValues());
      }
    });
  }

  setCanonicalUrl() {
    //dummy call to wait for translations
    const addCanonical = (route, searchArgs) => {
      this.seo.addCanonicalLinkTag(this.urlHelper.getSearchPageRoute(route, searchArgs.what, searchArgs.where, true));
    }

    this.getSearchRoute(addCanonical);
  }

  routeToPage() {
    const navigateToRoute = (route, searchArgs) => {
      this.router.navigate([this.urlHelper.getSearchPageRouteWithPage(route, searchArgs.what, searchArgs.where, searchArgs.page)], { queryParamsHandling: 'merge' });
    }

    this.getSearchRoute(navigateToRoute);
  }

  getUrlDisableDebugMode() {
    const removeDebugFromUrl = (route, searchArgs) => {
      localStorage.setItem('debugMode', 'false');
      this.router.navigate([this.urlHelper.getSearchPageRouteWithPage(route, searchArgs.what, searchArgs.where, searchArgs.page)], { queryParams: { debug: null }, queryParamsHandling: 'merge' });
    }

    this.getSearchRoute(removeDebugFromUrl);
  }

  disableDebugMode() {
    this.getUrlDisableDebugMode();
  }

  loadResults() {
    this.loading = true;
    const searchArgs: SearchArguments = this.searchStateService.getCurrentValues();
    
    if (this.urlHelper.checkUrl(searchArgs.what) || this.urlHelper.checkUrl(searchArgs.where)) {
      this.loading = false;
      this.router.navigate(['/']);
      return;
    }
    
    this.seo.setResultPageTitle(searchArgs.what, searchArgs.where);
    if (this.resultPageType == "dynamic") {
      this.seo.removeCanonicalLinkTag();
    } else {
      this.setCanonicalUrl();
    }
    
    // search results
    this.searchResults$ = this.shareDataService.fetchResults(searchArgs)
      .pipe(
        switchMap((data: SearchResults) => {
          this.rawData = data;
          this.listings = this.shareDataService.getListings(data?.searchGroups);
          this.pois = this.shareDataService.getResultsPois(this.listings);
          this.showFallbackA1 = this.fallbackA1(data);
          this.numFound = this.shareDataService.getTotalCount(data?.searchGroups);
          
          this.langSwitch.getSearchPage(data?.data?.analyzedSubject, data?.data?.analyzedLocation, searchArgs.what, searchArgs.where, searchArgs.searchType, searchArgs.page);
          this.seo.addAlternateLanguageTags(this.langSwitch);
          console.log("here to check");
          if (this.resultPageType == "dynamic") {
            this.seo.addMetaTagRobots(MetaTagType.NOINDEX);
          }

          this.localResultsLink = this.getLocalResultsLink(searchArgs.what, searchArgs.where, searchArgs.page);
          this.adKey = data?.data?.advertsCodeInfo?.adKey || null;
          this.mostPopularArticles$ = this.shareDataService.fetchPopularBlog({ headingId: data?.data?.analyzedSubject?.id, platform: BLOG_PLATFORM, limit: 3 });
          this.relatedCities$ = this.shareDataService.fetchRelatedCities(searchArgs.where);
          this.handleEditorials(data);

          this.trackingSearchData = this.trackingService.getResultsTrackingValues(this.listings, this.numFound);
          this.trackingService.trackEvent("SEARCH", "ResultPageLoad", this.trackingSearchData);

          this.loading = false;
          return of(data);
        }),
        shareReplay(1)
      );
    
    this.bannerService.refreshRevive();
  }

  handleEditorials(data: SearchResults) {
    // default editorial
    if (data?.data?.analyzedSubject?.editorialTop || data?.data?.analyzedSubject?.editorialBottom) {
      this.editorials$ = this.getEditorial(data?.data?.analyzedSubject);
    }

    // editorial with location
    if (data?.data?.analyzedLocation !== null && data?.data?.analyzedSubject !== null) {
      const city = data?.data?.analyzedLocation?.name;

      this.editorials$ = this.shareDataService.fetchEditorial(city, data?.data?.analyzedSubject?.id)
        .pipe(
          switchMap((editorialData) => {
            const editorial = editorialData?.length > 0 ? editorialData[0] : data?.data?.analyzedSubject;
            return this.getEditorial(editorial);
          })
        )
    }
  }

  getLocalResultsLink(what: string, where: string, page: number): string {
    let route = this.translate.instant('Routes.Search.LocalResults');
    route = route.replace(':what', what).replace(':where', where).replace(':page', page);
    return route;
  }

  fallbackA1(searchResults: SearchResults): boolean {
    const results = searchResults.searchGroups;
    return results.hasOwnProperty('A1')
      ? results['A1'].numFound > results['A1']?.listings?.length
      : false;
  }

  getEditorial(data): Observable<Editorials> {
    const belgiumTrans = this.translate.instant('Belgium');
    let editorialTop = "";
    if (data?.editorialTop) {
      editorialTop = data?.editorialTop;
    }

    let editorialBottom = "";
    if (data?.editorialBottom) {
      editorialBottom = data?.editorialBottom;
    }

    return of({
      "top": editorialTop
        .replaceAll("{location}", belgiumTrans),
      "bottom": editorialBottom
        .replaceAll("{location}", belgiumTrans)
    });
  }

  handleMobileMap() {
    if (window.innerWidth > 767) {
      this.renderer.removeClass(document.body, 'mobile-map');
    } else {
      this.renderer.addClass(document.body, 'mobile-map');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleMobileMap();
  }

  openVizualiser(searchResults: SearchResults) {
    const queryParams = {
      headingRadius: searchResults?.data?.analyzedSubject?.radius, 
      normalizedSearch: this.resultPageType === 'normalized',
      circleLat: searchResults?.data.analyzedLocation?.latitude,
      circleLng: searchResults?.data.analyzedLocation?.longitude,
      wkt: searchResults?.data.analyzedLocation?.wkt
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/visualizer"], {queryParams})
    );
  
    window.open(url, '_blank');
  }
}