import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class UrlHelper {
    domain: string;
    constructor() {
        this.domain = document.location.protocol + '//' + document.location.host;
    }

    getSearchPageRoute(route: string, what: string, where?: string, withDomain?: boolean): string | null {
        if (!route || !what) {
            return null;
        }
        
        const formattedWhat = this.customEncodeURIComponent(what);
        const formattedWhere = this.customEncodeURIComponent(where) || "";
        const whereReplace = `${!where ? "/:" : ":"}where`;
        const searchUrl = route
            .replace(":what", formattedWhat)
            .replace(whereReplace, formattedWhere)
            .replace("/:page", "");

        return withDomain ? this.domain + searchUrl : searchUrl;
    }

    getSearchPageRouteWithPage(route: string, what: string, where: string, page: number): string | null {
        if (!route || !what) {
            return null;
        }

        const formattedWhat = this.customEncodeURIComponent(what);
        const formattedWhere = this.customEncodeURIComponent(where) || "";
        const correctPage = page > 1 ? `/${page}` : "";
        const whereReplace = `${!where ? "/:" : ":"}where`;
        const searchUrlWithPage = route
            .replace(":what", formattedWhat)
            .replace(whereReplace, formattedWhere)
            .replace("/:page", correctPage);

        return searchUrlWithPage;
    }

    getDetailPageRoute(route: string, city: string, id: string, title: string, withDomain: boolean = false): string | null {
        if (!route || !id) {
            return null;
        }

        let res = route.replace(":id", id);
        const formattedCity = this.customEncodeURIComponent(city) || "-";
        const formattedTitle = this.customEncodeURIComponent(title) || "-";
        const detailUrl = res
            .replace(":city", formattedCity)
            .replace(":title", formattedTitle);
    
        return withDomain ? this.domain + detailUrl : detailUrl;
    }

    getFolderDetailPageRoute(route: string, childId: string, id: string, title: string, withDomain: boolean = false): string | null { 
        if (!route || !id) {
            return null
        }
        
        let res = route.replace(":id", id);
        const formattedChildId = childId || "-";
        const formattedTitle = this.customEncodeURIComponent(title) || "-";
        const detailUrl = res
            .replace(":childId", formattedChildId)
            .replace(":title", formattedTitle);
    
        return withDomain ? this.domain + detailUrl : detailUrl;
    }

    getBlogPageRoute(route: string, heading?: string, id?: string, title?: string): string | null {
        if (!route) {
            return null;
        }
    
        const formattedHeading = this.customEncodeURIComponent(heading) || "all";
        const formattedId = id || "";
        const formattedTitle = this.customEncodeURIComponent(title) || "-";
    
        let detailUrl = route
            .replace(":heading", formattedHeading)
            .replace("/:id", formattedId ? `/${formattedId}` : "")
            .replace("/:title", formattedTitle !== "-" ? `/${formattedTitle}` : "")
            .replace(":page", '1');
    
        return detailUrl;
    }

    getIndexPageRoute(route: string, letter: string = "A", page: string = "1"): string | null {
        if (!route) {
            return null;
        }
    
        let indexUrl = route
            .replace(":letter", letter)
            .replace(":page", page);
    
        return indexUrl;
    }

    customEncodeURIComponent(str: string): string {
        if (!str) return null;
        return this.sanitizeValue(str);
    }

    checkUrl(text: string): boolean {
        if (!text || text == "") {
            return false;
        }

        if (text.includes("<") ||
            text.includes(">") ||
            text.includes("\\") ||
            text.includes("//") ||
            text.includes("^")) {
            return true;
        } 

        return false
    }

    sanitizeValue(value: string): string {
        if (!value) return null;
        return value.replaceAll(/[\/]/g, '-').replaceAll(/[{}[\]]/g, '').replaceAll("q_", "");
    }

    serializeQueryParams(params: any): string {
        return "?" + Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');
    }
}