import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Endpoints } from '../../../constants/endpoints';
import { UrlHelper } from '../../../helpers/url-builder.helper';
import { DataService } from '../../../services/data.service';
import { LangSwitch } from '../../../services/lang-switch.service';
import { KeyValuePair } from '../../../models/query-results.model';
import { Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { CategoryLink } from '../../detail/components/categories/categories.component';
import { Title } from '@angular/platform-browser';

export class CategoriesRequest {
  letter: string = '';
  take: number = 0;
  skip: number = 0;
}

export interface CategoriesResponse {
  categories: KeyValuePair<string, number>[];
  numFound: number;
}

@Component({
  selector: 'app-category-index',
  templateUrl: './category-index.component.html',
  styleUrl: './category-index.component.scss'
})
export class CategoryIndexComponent implements OnInit, OnDestroy {
  take: number = 100;
  letter: string = null;
  page: number;
  numFound: number = 0;

  result: KeyValuePair<string,number>[];
  request: CategoriesRequest = new CategoriesRequest();

  categories$: Observable<KeyValuePair<string, number>[]>;

  alphabet: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  indexes: CategoryLink[] = [];
  categories: CategoryLink[] = [];

  destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private langSwitch: LangSwitch,
    private data: DataService,
    private translate: TranslateService,
    private urlHelper: UrlHelper,
    private title: Title
  ){}

  ngOnInit(): void {
    this.route.paramMap.subscribe((map) => {
      this.letter = map.get("letter") || "A";
      this.page = parseInt(map.get("page")) || 1;
      
      this.initIndexes();
      this.loadIndex();
      this.langSwitch.setCategoryIndex(this.letter, this.page)

      this.translate.get('Categories').subscribe(title => {
        this.title.setTitle(`${title} | ${this.letter} ${this.page} | ${document.location.host}`);
      })
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadIndex() {
    this.request.letter = this.letter;
    this.request.skip = this.page > 1 ? (this.page - 1) * this.take : 0;
    this.request.take = this.take;
    this.categories$ = this.data.post<KeyValuePair<string, number>[]>(Endpoints.CATEGORIES(this.translate.currentLang), this.request)
      .pipe(switchMap(data => {
        this.initCategories(data);
        this.numFound = 0;
        return of(data);
      }))
  }

  initIndexes(): void {
    this.translate.get("Routes.Index.CategoryIndex")
      .pipe(takeUntil(this.destroy$))
      .subscribe(route => {
        this.indexes = this.alphabet.map(letter => {
          return new CategoryLink(
            letter,  
            this.urlHelper.getIndexPageRoute(route, letter)
          );
        })
      });
  }

  initCategories(categories: KeyValuePair<string,number>[]): void {
    this.categories = categories.map(category => {
      return new CategoryLink(
        category.key, 
        this.urlHelper.getSearchPageRoute(this.translate.instant('Routes.Search.NormalizedWhatWhereSearch'), category.key)
      );
    })
  }

  switchPage(event) {
    if (event && event.page) {
      this.page = event.page;
    }
    this.translate.get("Routes.Index.KeywordIndex")
      .pipe(takeUntil(this.destroy$))
      .subscribe((url: string) => {
        const nextPageUrl = url.replace(":letter", this.letter).replace(":page", `${this.page}`);
        this.router.navigate([nextPageUrl]);
      });
  }
}