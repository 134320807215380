<article >
    <a [routerLink]="href"
        [trackingAction]="customTrackingAction || 'BlogArticleClick'"
        [trackingCategory]="customTrackingCategory || 'BLOG'"
        [trackingArticleId]="article?.id">
        <div class="pointer-events-none">
            <div class="article__thumbnail">
                <img [src]="article?.thumbnailPath" [alt]="article?.title">
            </div>
            <ng-container *ngIf="hTag == 'h2'; else h3">
                <h2 class="text-1xl font-medium mb-2.5 block">{{ article?.title }}</h2>
            </ng-container>
            <ng-template #h3>
                <ng-container *ngIf="hTag =='h3'; else h4">
                    <h3 class="text-1xl font-medium mb-2.5 block">{{ article?.title }}</h3>
                </ng-container>
                <ng-template #h4>
                    <h4 class="text-1xl font-medium mb-2.5 block">{{ article?.title }}</h4>
                </ng-template>
            </ng-template>
            <div class="text-xs font-semibold mb-2.5">
                {{ formatedDate }}
            </div>
            <div class="text-sm font-normal text-gray-400">
                <span>
                    {{ excerpt }}
                </span>
            </div>
        </div>
    </a>
</article>
